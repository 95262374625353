import React from 'react'

// custom components
import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Card from '../components/Card'
import Grid from '../components/Grid'
import Intro from '../components/Intro'

//images
import services from '../images/services.svg'
import manager from '../images/manager.svg'
import developer from '../images/webdeveloper.svg'
import strategy from '../images/strategy.svg'
import social from '../images/social.svg'
import ad from '../images/ad.svg'
import seo from '../images/seo.svg'
import email from '../images/email.svg'

const ServicesPage = () => {
  return (
    <Layout>
      <SEO
        title="Full Stack Marketing Digital Marketing Agency in Wellington, New Zealand"
        description="How can we help? Need a hand with email marketing? Website design or development? Social Media? Adwords? We are here to help. Learn more here"
      />

      <Header
        title="Full stack web marketing"
        sub="From ideas to action, we can help you execute a winning strategy."
        image={services}
      />

      <Intro>
        <h2>All the essential web marketing services covered</h2>
        <p>
          Giving the right message to the right person, at the right time,
          using the right tool is the key to successful marketing. Our web
          marketing agency is a one stop shop that can help you have the
          professional presence necessary to get results no matter how big or
          small your business is.
        </p>
      </Intro>
      <Grid>
        <Card
          image={manager}
          alt="On demand management"
          title="Marketing management on demand"
          description="If you have a small junior marketing team we can provide the leadership and strategy services you need to help your team grow their skills and get results."
        />
        <Card
          image={developer}
          alt="Web development services"
          title="Web development"
          description="From single page marketing sites through to complex e-commerce web sites, we can help plan, design and deliver a website for your business using Wordpress or GatsbyJs."
        />
        <Card
          image={strategy}
          alt="Website strategy"
          title="Strategy Development"
          description="We work with boards and owners to help develop winning marketing strategies which will help you achieve your business goals."
        />
        <Card
          image={social}
          alt="Social media marketing"
          title="Social media marketing"
          description="Get a strategy in place to reach your target audience on platforms like Facebook, LinkedIn and Instagram. We can help you generate content that grabs people's attention."
        />
        <Card
          image={ad}
          alt="Digital advertising"
          title="Digital Advertising"
          description="Get noticed with our fully managed online advertising solutions on Google Adwords, Facebook advertising and LinkedIn advertising."
        />
        <Card
          image={seo}
          alt="Seo"
          title="Search engine optimisation"
          description="SEO is still one of the most successful ways to get people to your website. We take care of the technical parts of SEO to increase the chances of you getting top results on Google."
        />
        <Card
          image={email}
          alt="Email Marketing"
          title="Email marketing"
          description="Email marketing is one of the best ways to create an ongoing relationship with your audience. We can set up your email marketing platform and help you generate content that drives clicks to your website."
        />
      </Grid>
    </Layout>
  )
}

export default ServicesPage
